import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const ProductsSection = styled.section`
  padding: 6rem 2rem;
  background-color: var(--background-color);
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  text-align: center;
  color: var(--secondary-color);
  font-size: 2.5rem;
  margin-bottom: 3rem;
`;

const Categories = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`;

const CategoryButton = styled.button`
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 25px;
  background-color: ${({ $active }) =>
    $active ? 'var(--primary-color)' : 'white'};
  color: ${({ $active }) =>
    $active ? 'white' : 'var(--secondary-color)'};
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;

  &:hover {
    background-color: var(--primary-color);
    color: white;
  }
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const ProductCard = styled(motion.div)`
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ProductImage = styled.div`
  height: 250px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  ${ProductCard}:hover & img {
    transform: scale(1.1);
  }
`;

const ProductInfo = styled.div`
  padding: 1.5rem;
`;

const ProductName = styled.h3`
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
`;

const ProductDescription = styled.p`
  color: var(--light-text-color);
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const ProductMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--primary-color);
  font-weight: 600;
`;

const ContactInfo = styled.p`
  font-size: 0.9rem;
`;

const Products = () => {
  const [activeCategory, setActiveCategory] = useState('all');

  const categories = [
    { id: 'all', name: 'Tous les produits' },
    { id: 'beef', name: 'Bœuf' },
    { id: 'veal', name: 'Veau' },
    { id: 'lamb', name: 'Agneau' },
    { id: 'farm', name: 'Ferme' }
  ];

  const products = [
    {
      id: 1,
      name: 'Côte de Bœuf',
      category: 'beef',
      description: "Une pièce d'exception, tendre et savoureuse, issue de nos bovins élevés en plein air.",
      image: 'https://images.unsplash.com/photo-1603048297172-c92544798d5a?auto=format&fit=crop&w=800&q=80',
      price: 'Sur demande'
    },
    {
      id: 2,
      name: 'Veau Rosé',
      category: 'veal',
      description: 'Veau élevé sous la mère, une viande tendre au goût authentique.',
      image: 'https://images.unsplash.com/photo-1602470520998-f4a52199a3d6?auto=format&fit=crop&w=800&q=80',
      price: 'Sur demande'
    },
    {
      id: 3,
      name: "Gigot d'Agneau",
      category: 'lamb',
      description: 'Agneau du Jura, élevé en pâturage de montagne pour une saveur unique.',
      image: 'https://images.unsplash.com/photo-1628268909376-e8c44bb3153f?auto=format&fit=crop&w=800&q=80',
      price: 'Sur demande'
    },
    {
      id: 4,
      name: 'Entrecôte',
      category: 'beef',
      description: 'Une pièce noble, parfaitement persillée pour un maximum de goût.',
      image: 'https://images.unsplash.com/photo-1603048297172-c92544798d5a?auto=format&fit=crop&w=800&q=80',
      price: 'Sur demande'
    },
    {
      id: 5,
      name: 'Blanquette de Veau',
      category: 'veal',
      description: 'Morceaux choisis pour une blanquette traditionnelle et savoureuse.',
      image: 'https://images.unsplash.com/photo-1602470520998-f4a52199a3d6?auto=format&fit=crop&w=800&q=80',
      price: 'Sur demande'
    },
    {
      id: 6,
      name: 'Côtelettes d\'Agneau',
      category: 'lamb',
      description: 'Délicates côtelettes d\'agneau, idéales pour le grill.',
      image: 'https://images.unsplash.com/photo-1544025162-d76694265947?auto=format&fit=crop&w=800&q=80',
      price: 'Sur demande'
    }
  ];

  const filteredProducts = activeCategory === 'all'
    ? products
    : products.filter(product => product.category === activeCategory);

  return (
    <ProductsSection id="products">
      <Container>
        <Title>Nos Produits</Title>
        
        <Categories>
          {categories.map(category => (
            <CategoryButton
              key={category.id}
              $active={activeCategory === category.id}
              onClick={() => setActiveCategory(category.id)}
            >
              {category.name}
            </CategoryButton>
          ))}
        </Categories>

        <ProductsGrid>
          <AnimatePresence mode="wait">
            {filteredProducts.map(product => (
              <ProductCard
                key={product.id}
                layout
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.6 }}
              >
                <ProductImage>
                  <img src={product.image} alt={product.name} />
                </ProductImage>
                <ProductInfo>
                  <ProductName>{product.name}</ProductName>
                  <ProductDescription>{product.description}</ProductDescription>
                  <ProductMeta>
                    <span>{product.price}</span>
                    <ContactInfo>Contactez-nous</ContactInfo>
                  </ProductMeta>
                </ProductInfo>
              </ProductCard>
            ))}
          </AnimatePresence>
        </ProductsGrid>
      </Container>
    </ProductsSection>
  );
};

export default Products;
