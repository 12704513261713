import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faQuoteLeft, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const TestimonialsContainer = styled.section`
  padding: 4rem 2rem;
  background-color: white;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  text-align: center;
  color: var(--secondary-color);
  margin-bottom: 3rem;
`;

const TestimonialSlider = styled.div`
  position: relative;
  overflow: hidden;
  padding: 2rem 0;
`;

const TestimonialWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Testimonial = styled(motion.div)`
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const QuoteIcon = styled.div`
  color: var(--primary-color);
  font-size: 2rem;
  margin-bottom: 1rem;
  opacity: 0.5;
`;

const TestimonialText = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
`;

const Author = styled.div`
  margin-top: 2rem;
`;

const AuthorName = styled.h4`
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
`;

const AuthorInfo = styled.p`
  color: var(--light-text-color);
  font-size: 0.9rem;
`;

const Rating = styled.div`
  color: #f1c40f;
  margin: 1rem 0;
`;

const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 2;
  
  &:hover {
    background-color: var(--primary-color);
    color: white;
  }

  ${({ direction }) => direction === 'prev' ? 'left: 0;' : 'right: 0;'}

  @media (max-width: 768px) {
    display: none;
  }
`;

const Dots = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 2rem;
`;

const Dot = styled.button`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: none;
  background-color: ${({ active }) => active ? 'var(--primary-color)' : '#ddd'};
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ active }) => active ? 'var(--primary-color)' : '#bbb'};
  }
`;

const AddTestimonialButton = styled.button`
  display: block;
  margin: 2rem auto 0;
  padding: 1rem 2rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c0392b;
  }
`;

// Données de démonstration
const testimonials = [
  {
    id: 1,
    text: "La qualité de la viande est exceptionnelle. On sent vraiment la différence avec les produits industriels. Le service est également impeccable !",
    author: "Marie Dupont",
    location: "Lons-le-Saunier",
    rating: 5,
    date: "15 décembre 2023"
  },
  {
    id: 2,
    text: "Je suis client depuis plus d'un an et je n'ai jamais été déçu. Les produits sont toujours frais et la livraison est rapide.",
    author: "Jean Martin",
    location: "Dole",
    rating: 5,
    date: "20 novembre 2023"
  },
  {
    id: 3,
    text: "Une vraie boucherie artisanale comme on les aime ! Les conseils sont précieux et la viande est délicieuse.",
    author: "Sophie Laurent",
    location: "Saint-Claude",
    rating: 5,
    date: "5 décembre 2023"
  }
];

const TestimonialsSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);

  const slideVariants = {
    enter: (direction) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    })
  };

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  const paginate = (newDirection) => {
    setDirection(newDirection);
    setCurrentIndex((prevIndex) => (
      (prevIndex + newDirection + testimonials.length) % testimonials.length
    ));
  };

  return (
    <TestimonialsContainer>
      <Container>
        <Title>Ce que disent nos clients</Title>
        
        <TestimonialSlider>
          <NavigationButton
            direction="prev"
            onClick={() => paginate(-1)}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </NavigationButton>

          <AnimatePresence initial={false} custom={direction}>
            <TestimonialWrapper>
              <Testimonial
                key={currentIndex}
                custom={direction}
                variants={slideVariants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 }
                }}
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                dragElastic={1}
                onDragEnd={(e, { offset, velocity }) => {
                  const swipe = swipePower(offset.x, velocity.x);

                  if (swipe < -swipeConfidenceThreshold) {
                    paginate(1);
                  } else if (swipe > swipeConfidenceThreshold) {
                    paginate(-1);
                  }
                }}
              >
                <QuoteIcon>
                  <FontAwesomeIcon icon={faQuoteLeft} />
                </QuoteIcon>
                <TestimonialText>
                  {testimonials[currentIndex].text}
                </TestimonialText>
                <Rating>
                  {[...Array(testimonials[currentIndex].rating)].map((_, i) => (
                    <FontAwesomeIcon key={i} icon={faStar} />
                  ))}
                </Rating>
                <Author>
                  <AuthorName>{testimonials[currentIndex].author}</AuthorName>
                  <AuthorInfo>
                    {testimonials[currentIndex].location} - {testimonials[currentIndex].date}
                  </AuthorInfo>
                </Author>
              </Testimonial>
            </TestimonialWrapper>
          </AnimatePresence>

          <NavigationButton
            direction="next"
            onClick={() => paginate(1)}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </NavigationButton>
        </TestimonialSlider>

        <Dots>
          {testimonials.map((_, index) => (
            <Dot
              key={index}
              active={currentIndex === index}
              onClick={() => {
                setDirection(index > currentIndex ? 1 : -1);
                setCurrentIndex(index);
              }}
            />
          ))}
        </Dots>

        <AddTestimonialButton>
          Partagez votre expérience
        </AddTestimonialButton>
      </Container>
    </TestimonialsContainer>
  );
};

export default TestimonialsSection;
