import React from 'react';
import styled from 'styled-components';

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 1rem 2rem;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const NavContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled.a`
  color: var(--text-color);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;

  &:hover {
    color: var(--primary-color);
  }
`;

const Navigation = () => {
  return (
    <Nav>
      <NavContainer>
        <Logo>Viande Nature Jura</Logo>
        <NavLinks>
          <NavLink href="#about">À propos</NavLink>
          <NavLink href="#products">Nos Produits</NavLink>
          <NavLink href="#gallery">Galerie</NavLink>
          <NavLink href="#contact">Contact</NavLink>
          <NavLink href="/blog">Blog</NavLink>
        </NavLinks>
      </NavContainer>
    </Nav>
  );
};

export default Navigation;
