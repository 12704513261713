import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const GallerySection = styled.section`
  padding: 4rem 2rem;
  background-color: var(--background-color);
`;

const GalleryContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  text-align: center;
  color: var(--secondary-color);
  margin-bottom: 2rem;
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const GalleryItem = styled(motion.div)`
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  aspect-ratio: 1;

  &:hover img {
    transform: scale(1.05);
  }
`;

const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
`;

const Modal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 2rem;
`;

const ModalContent = styled.div`
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
`;

const ModalImage = styled.img`
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1001;
  padding: 0.5rem;
  
  &:hover {
    color: var(--primary-color);
  }
`;

const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 1rem;
  z-index: 1001;
  
  &:hover {
    color: var(--primary-color);
  }

  ${({ direction }) => direction === 'prev' ? 'left: 1rem;' : 'right: 1rem;'}
`;

const Caption = styled.div`
  color: white;
  text-align: center;
  margin-top: 1rem;
  font-size: 1.1rem;
`;

const CategoryFilter = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`;

const FilterButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: ${({ active }) => active ? 'var(--primary-color)' : 'white'};
  color: ${({ active }) => active ? 'white' : 'var(--secondary-color)'};
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${({ active }) => active ? '#c0392b' : '#f8f9fa'};
  }
`;

// Données de démonstration
const categories = [
  { id: 'all', label: 'Tout' },
  { id: 'farm', label: 'La Ferme' },
  { id: 'products', label: 'Nos Produits' },
  { id: 'preparation', label: 'Préparation' }
];

const galleryItems = [
  {
    id: 1,
    src: 'https://images.unsplash.com/photo-1500382017468-9049fed747ef?auto=format&fit=crop&w=800&q=80',
    caption: 'Pâturages du Jura',
    category: 'farm'
  },
  {
    id: 2,
    src: 'https://images.unsplash.com/photo-1516467508483-a7212febe31a?auto=format&fit=crop&w=800&q=80',
    caption: 'Notre ferme dans le Jura',
    category: 'farm'
  },
  {
    id: 3,
    src: 'https://images.unsplash.com/photo-1607623814075-e51df1bdc82f?auto=format&fit=crop&w=800&q=80',
    caption: 'Sélection de viandes',
    category: 'products'
  },
  {
    id: 4,
    src: 'https://images.unsplash.com/photo-1592686092916-672fa9e86866?auto=format&fit=crop&w=800&q=80',
    caption: 'Nos pâturages',
    category: 'farm'
  },
  {
    id: 5,
    src: 'https://images.unsplash.com/photo-1448907503123-67254d59ca4f?auto=format&fit=crop&w=800&q=80',
    caption: 'Préparation artisanale',
    category: 'preparation'
  }
];

const PhotoGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeCategory, setActiveCategory] = useState('all');

  const filteredImages = activeCategory === 'all'
    ? galleryItems
    : galleryItems.filter(item => item.category === activeCategory);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const handlePrevious = () => {
    const currentIndex = galleryItems.findIndex(item => item.id === selectedImage.id);
    const previousIndex = (currentIndex - 1 + galleryItems.length) % galleryItems.length;
    setSelectedImage(galleryItems[previousIndex]);
  };

  const handleNext = () => {
    const currentIndex = galleryItems.findIndex(item => item.id === selectedImage.id);
    const nextIndex = (currentIndex + 1) % galleryItems.length;
    setSelectedImage(galleryItems[nextIndex]);
  };

  return (
    <GallerySection>
      <GalleryContainer>
        <Title>Notre Galerie</Title>
        
        <CategoryFilter>
          {categories.map(category => (
            <FilterButton
              key={category.id}
              active={activeCategory === category.id}
              onClick={() => setActiveCategory(category.id)}
            >
              {category.label}
            </FilterButton>
          ))}
        </CategoryFilter>

        <GalleryGrid>
          {filteredImages.map(image => (
            <GalleryItem
              key={image.id}
              onClick={() => handleImageClick(image)}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              layout
            >
              <GalleryImage src={image.src} alt={image.caption} />
            </GalleryItem>
          ))}
        </GalleryGrid>

        <AnimatePresence>
          {selectedImage && (
            <Modal
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={handleClose}
            >
              <CloseButton onClick={handleClose}>
                <FontAwesomeIcon icon={faTimes} />
              </CloseButton>
              
              <NavigationButton direction="prev" onClick={(e) => {
                e.stopPropagation();
                handlePrevious();
              }}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </NavigationButton>

              <ModalContent onClick={e => e.stopPropagation()}>
                <ModalImage src={selectedImage.src} alt={selectedImage.caption} />
                <Caption>{selectedImage.caption}</Caption>
              </ModalContent>

              <NavigationButton direction="next" onClick={(e) => {
                e.stopPropagation();
                handleNext();
              }}>
                <FontAwesomeIcon icon={faChevronRight} />
              </NavigationButton>
            </Modal>
          )}
        </AnimatePresence>
      </GalleryContainer>
    </GallerySection>
  );
};

export default PhotoGallery;
