import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPhone, 
  faEnvelope, 
  faMapMarkerAlt, 
  faClock 
} from '@fortawesome/free-solid-svg-icons';

const ContactSection = styled.section`
  padding: 6rem 2rem;
  background-color: white;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  text-align: center;
  color: var(--secondary-color);
  font-size: 2.5rem;
  margin-bottom: 3rem;
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const InfoCard = styled(motion.div)`
  background: var(--background-color);
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  color: white;
  font-size: 1.5rem;
`;

const InfoTitle = styled.h3`
  color: var(--secondary-color);
  margin-bottom: 1rem;
`;

const InfoText = styled.p`
  color: var(--light-text-color);
  line-height: 1.6;
`;

const MapContainer = styled.div`
  margin-top: 4rem;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Map = styled.iframe`
  width: 100%;
  height: 400px;
  border: none;
`;

const OpeningHours = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem 1rem;
  max-width: 200px;
  margin: 0 auto;
  text-align: left;
`;

const Day = styled.span`
  color: var(--secondary-color);
  font-weight: 500;
`;

const Hours = styled.span`
  color: var(--light-text-color);
`;

const Contact = () => {
  return (
    <ContactSection id="contact">
      <Container>
        <Title>Nous Contacter</Title>
        
        <ContactGrid>
          <InfoCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <IconWrapper>
              <FontAwesomeIcon icon={faPhone} />
            </IconWrapper>
            <InfoTitle>Téléphone</InfoTitle>
            <InfoText>
              <a href="tel:+33384123456" style={{ color: 'inherit', textDecoration: 'none' }}>
                03 84 12 34 56
              </a>
            </InfoText>
          </InfoCard>

          <InfoCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <IconWrapper>
              <FontAwesomeIcon icon={faEnvelope} />
            </IconWrapper>
            <InfoTitle>Email</InfoTitle>
            <InfoText>
              <a href="mailto:contact@viande-nature-jura.fr" style={{ color: 'inherit', textDecoration: 'none' }}>
                contact@viande-nature-jura.fr
              </a>
            </InfoText>
          </InfoCard>

          <InfoCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <IconWrapper>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </IconWrapper>
            <InfoTitle>Adresse</InfoTitle>
            <InfoText>
              123 Route des Pâturages<br />
              39000 Lons-le-Saunier
            </InfoText>
          </InfoCard>

          <InfoCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            <IconWrapper>
              <FontAwesomeIcon icon={faClock} />
            </IconWrapper>
            <InfoTitle>Horaires d'ouverture</InfoTitle>
            <OpeningHours>
              <Day>Lundi:</Day>
              <Hours>Fermé</Hours>
              <Day>Mar-Ven:</Day>
              <Hours>9h-12h, 14h-18h</Hours>
              <Day>Samedi:</Day>
              <Hours>9h-12h</Hours>
              <Day>Dimanche:</Day>
              <Hours>Fermé</Hours>
            </OpeningHours>
          </InfoCard>
        </ContactGrid>

        <MapContainer
          as={motion.div}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.8 }}
        >
          <Map
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d44083.85735490371!2d5.521145824994645!3d46.67447449607821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478cd8e9f9f94b65%3A0x408ab2ae4bfb580!2s39000%20Lons-le-Saunier!5e0!3m2!1sfr!2sfr!4v1656431234567!5m2!1sfr!2sfr"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </MapContainer>
      </Container>
    </ContactSection>
  );
};

export default Contact;
