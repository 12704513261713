import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const AboutSection = styled.section`
  padding: 6rem 2rem;
  background-color: white;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const ImageContainer = styled(motion.div)`
  position: relative;
  
  @media (max-width: 968px) {
    order: -1;
  }
`;

const MainImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const FloatingImage = styled(motion.img)`
  position: absolute;
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:nth-child(2) {
    top: -30px;
    right: -30px;
  }

  &:nth-child(3) {
    bottom: -30px;
    left: -30px;
  }

  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
`;

const Content = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;

const Title = styled(motion.h2)`
  color: var(--secondary-color);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled(motion.p)`
  color: var(--light-text-color);
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const Features = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;

  @media (max-width: 968px) {
    justify-items: center;
  }
`;

const Feature = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 968px) {
    flex-direction: column;
    text-align: center;
  }
`;

const FeatureIcon = styled.div`
  width: 50px;
  height: 50px;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
`;

const FeatureText = styled.div`
  h3 {
    color: var(--secondary-color);
    margin-bottom: 0.5rem;
  }

  p {
    color: var(--light-text-color);
    font-size: 0.9rem;
  }
`;

const About = () => {
  return (
    <AboutSection id="about">
      <Container>
        <ImageContainer
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <MainImage 
            src="https://images.unsplash.com/photo-1516467508483-a7212febe31a?auto=format&fit=crop&w=1200&q=80" 
            alt="Élevage en plein air" 
          />
          <FloatingImage
            src="https://images.unsplash.com/photo-1560781290-7dc94c0f8f4f?auto=format&fit=crop&w=800&q=80"
            alt="Détail élevage"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.2 }}
          />
          <FloatingImage
            src="https://images.unsplash.com/photo-1500595046743-cd271d694d30?auto=format&fit=crop&w=800&q=80"
            alt="Pâturage"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.4 }}
          />
        </ImageContainer>

        <Content>
          <Title
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            Notre Histoire et Nos Valeurs
          </Title>
          <Description
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            Depuis plus de 20 ans, nous élevons nos animaux en plein air dans le respect 
            des traditions jurassiennes. Notre engagement pour le bien-être animal et 
            la qualité de notre viande guide chacune de nos actions.
          </Description>

          <Features>
            <Feature
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              <FeatureIcon>🌿</FeatureIcon>
              <FeatureText>
                <h3>100% Plein Air</h3>
                <p>Nos animaux pâturent librement toute l'année</p>
              </FeatureText>
            </Feature>

            <Feature
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.5 }}
            >
              <FeatureIcon>🏔️</FeatureIcon>
              <FeatureText>
                <h3>Terroir Jurassien</h3>
                <p>Une viande d'exception issue de nos montagnes</p>
              </FeatureText>
            </Feature>

            <Feature
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.6 }}
            >
              <FeatureIcon>❤️</FeatureIcon>
              <FeatureText>
                <h3>Bien-être Animal</h3>
                <p>Respect et soin quotidien de nos bêtes</p>
              </FeatureText>
            </Feature>

            <Feature
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.7 }}
            >
              <FeatureIcon>🌱</FeatureIcon>
              <FeatureText>
                <h3>Agriculture Durable</h3>
                <p>Pratiques respectueuses de l'environnement</p>
              </FeatureText>
            </Feature>
          </Features>
        </Content>
      </Container>
    </AboutSection>
  );
};

export default About;
