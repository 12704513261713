import React from 'react';
import styled from 'styled-components';
import GlobalStyles from './styles/GlobalStyles';
import Navigation from './components/Navigation';
import Hero from './components/Hero';
import About from './components/About';
import Products from './components/Products';
import PhotoGallery from './components/PhotoGallery';
import TestimonialsSection from './components/TestimonialsSection';
import Contact from './components/Contact';
import Footer from './components/Footer';

const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.main`
  flex: 1;
`;

const App = () => {
  return (
    <AppContainer>
      <GlobalStyles />
      <Navigation />
      <MainContent>
        <Hero />
        <About />
        <Products />
        <PhotoGallery />
        <TestimonialsSection />
        <Contact />
      </MainContent>
      <Footer />
    </AppContainer>
  );
};

export default App;
