import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: var(--secondary-color);
  color: white;
  padding: 3rem 2rem;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const FooterSection = styled.div`
  h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
`;

const FooterLink = styled.a`
  color: white;
  text-decoration: none;
  display: block;
  margin-bottom: 0.5rem;
  
  &:hover {
    color: var(--primary-color);
  }
`;

const ContactInfo = styled.div`
  margin-top: 1rem;
  
  p {
    margin-bottom: 0.5rem;
  }
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <h3>Viande Nature Jura</h3>
          <p>Votre boucher de confiance dans le Jura, offrant des produits de qualité issus d'élevages locaux.</p>
        </FooterSection>
        
        <FooterSection>
          <h3>Liens Rapides</h3>
          <FooterLink href="#about">À propos</FooterLink>
          <FooterLink href="#products">Nos Produits</FooterLink>
          <FooterLink href="#gallery">Galerie</FooterLink>
          <FooterLink href="/blog">Blog</FooterLink>
          <FooterLink href="#contact">Contact</FooterLink>
        </FooterSection>
        
        <FooterSection>
          <h3>Contact</h3>
          <ContactInfo>
            <p>123 Rue du Jura</p>
            <p>39000 Lons-le-Saunier</p>
            <p>Tél: 03 84 XX XX XX</p>
            <p>Email: contact@viande-nature-jura.fr</p>
          </ContactInfo>
        </FooterSection>
      </FooterContent>
      
      <Copyright>
        <p>&copy; {new Date().getFullYear()} Viande Nature Jura. Tous droits réservés.</p>
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
